import React from 'react';

function Topbar() {
    return (
        <div className="top-bar d-none d-md-block">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-12 text-white">
                        <p className="top-left">
                            Horario de atención: <span>Lunes a viernes 7:30am - 5:00pm y Sábados de 8:00am - 12:00pm</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Topbar;
