import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ScrollIndicator from './components/ScrollIndicator';
import ScrollToTopRoute from './components/ScrollTopRoute';
import About from './pages/About';
import Contact from './pages/Contact';
import Home1 from './pages/Home1';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Team from './pages/Team';
import ProjectDetails from "./pages/ProjectDetails";

function App() {
    return (
        <>
            <Router>
                <ScrollToTopRoute/>
                <Switch>
                    <Route exact path="/about">
                        <About/>
                    </Route>
                    <Route exact path="/team">
                        <Team/>
                    </Route>
                    <Route exact path="/projects">
                        <Projects/>
                    </Route>
                    <Route exact path="/services">
                        <Services/>
                    </Route>
                    <Route exact path="/contact">
                        <Contact/>
                    </Route>
                    <Route exact path="/">
                        <Home1/>
                    </Route>
                    <Route exact path="/detalles/:id" component={ProjectDetails} />
                </Switch>
            </Router>
            <ScrollIndicator/>
        </>
    );
}

export default App;
