import React from 'react';
import bannerBg from '../assets/img/personal/collage.jpg';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import ProjectFilter2 from '../components/ProjectFilter2';
import Services2 from '../components/Services2';
import Process2 from "../components/Process2";
import Footer3 from "../components/Footer3";

function Services() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBgMobile={bannerBg} bannerBgDesktop={bannerBg} currentPage="Servicios" heading="Servicios" />
            <Process2 />
            <Services2 />
            <ProjectFilter2 />
            <Footer3 />
        </>
    );
}

export default Services;
