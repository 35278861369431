import React from 'react';
import bannerBg from '../assets/img/personal/collage.jpg';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import PortfolioDetails from '../components/PortfolioDetails';
import Footer3 from "../components/Footer3";

function ProjectDetails() {
    return (
        <>
            <Header1 />
            <PageBanner
                bannerBgDesktop={bannerBg}
                bannerBgMobile={bannerBg}
                heading="Detalles de proyecto"
                currentPage="Detalles de proyecto"
            />
            <PortfolioDetails />
            <Footer3 />
        </>
    );
}

export default ProjectDetails;
