import { v4 as uuidv4 } from 'uuid';
import Img1 from '../../assets/img/personal/fiscalia-img.png';
import Img2 from '../../assets/img/personal/defecto.jpg';
import Img3 from '../../assets/img/personal/bdlezo/img-005.jpg';
import Img4 from '../../assets/img/personal/img-uspec.jpg';


const portfolioOneData = [
    {
        id: uuidv4(),
        image: Img1,
        category: '$97.417.785,68',
        client: 'Fiscalía general de la nación',
        heading: 'Regional caribe',
        desc: 'Realizar bajo la modalidad de precios unitarios fijos las obras de mejoramiento y mantenimiento de ' +
            'la infraestructura física de la sede ubicada en la Calle 1 No. 6-65 - Riohacha de la Fiscalía General de la ' +
            'Nación Regional Caribe, de acuerdo con las especificaciones técnicas establecidas por la Entidad.',
        btnText: 'Detalles',
    },

    {
        id: uuidv4(),
        image: Img2,
        category: '$599.999.788,04',
        client: 'ARMADA NACIONAL',
        heading: 'BASE NAVAL ARC',
        desc: 'El mantenimiento correctivo y preventivo de manera integral y a todo costo, por el sistema de precios ' +
            'unitarios, fijos y sin formula de reajuste de la infraestructura así: lote 3: edificio de comando del ' +
            'batallón de movilidad de infantería de marina no.1, en mahates bolivar, de acuerdo con las cantidades de ' +
            'obra y especificaciones técnicas descritas en el anexo a y b',
        btnText: 'Detalles',
    },

    {
        id: uuidv4(),
        image: Img3,
        category: '$6.400.000.000,00',
        client: 'E.S.E',
        heading: 'Hospital de Blas de Lezo',
        desc: 'Adecuación, mejoras locativas y/o mantenimiento de las instalaciones de la sede Blas de Lezo, la cual ' +
            'hace parte de la infraestructura de la E.S.E. Hospital Local Cartagena de Indias utilizada para la prestación ' +
            'de servicios de salud a población pobre y vulnerable, a precios unitarios fijos sin formula de reajuste según proyecto de inversión',
        btnText: 'Detalles',
    },

    {
        id: uuidv4(),
        image: Img4,
        category: '$ $ 12.958.037.256,24',
        client: 'INPEC',
        heading: 'Unidad de servicios penitenciarios y carcelario',
        desc: 'Mantenimiento general recurrente de la infraestructura física en los establecimientos penitenciarios y ' +
            'carcelarios a cargo del inpec - fase 1',
        btnText: 'Detalles',
    },
];

export default portfolioOneData;
