import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const contactData = [
    {
        id: uuidv4(),
        icon: <AiOutlineMail />,
        heading: 'Correo Electrónico',
        text: 'Contactanos via MAIL',
        item1: 'contacto@eidelca.com',
    },

    {
        id: uuidv4(),
        icon: <AiOutlinePhone />,
        heading: 'Teléfonos',
        text: 'Contactanos via Teléfono',
        item1: '(5) 6566637',
        item2: '+(57) 3107138609 ',
        item3: '+(57) 3502802177 - (Whatsapp)',
    },

    {
        id: uuidv4(),
        icon: <FaMapMarkerAlt />,
        heading: 'Oficina',
        text: 'Visitanos',
        item1: 'Barrio Bosque - Transversal 44. No. 21a - 44 Local 1.',
    },
];

export default contactData;
