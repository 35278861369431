import React from 'react';
import img1 from '../../assets/img/personal/img-004.jpg';
import img3 from '../../assets/img/personal/cau-img-001.jpeg';
import img2 from '../../assets/img/personal/TRABAJADORES.jpg';
import ProcessContent from './ProcessContent';
import ProcessGrid2 from './ProcessGrid2';

function Process2() {
    return (
        <section className="promo-featured-wrapper section-padding">
            <div className="container">
                <div className="row align-center">
                    <div className="col-xl-5 col-12 text-center">
                        <ProcessGrid2 img1={img1} img2={img2} img3={img3}/>
                    </div>
                    <div className="col-xl-7 col-12 mt-5 mt-xl-0">
                        <div className="block-contents ml-xl-5 ml-50">
                            <div className="section-title-3">
                                <h1>Proceso de nuestra empresa</h1>
                            </div>
                            <ProcessContent
                                text="Nuestro proceso de trabajo se centra en una planificación meticulosa y una
                                ejecución impecable, desde la fase de consultoría inicial hasta la entrega final del proyecto.
                                Abordamos cada desafío con una mezcla única de creatividad técnica y precisión operativa,
                                asegurando resultados que no solo cumplen sino que superan las expectativas de nuestros clientes."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Process2;
