import React from 'react';
import bannerBg from '../assets/img/personal/Contactanos.png';
import bannerBgsm from '../assets/img/personal/ContactanosSm.png';
import ContactUs from '../components/ContactUs';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import Footer3 from "../components/Footer3";

function Contact() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBgDesktop={bannerBg} bannerBgMobile={bannerBgsm} heading="Contactanos" currentPage="Contacto" />
            <ContactUs />
            {/*EMAILS DEHABILITIDAS YA QUE SON DE UN PLAN SUPERIOR*/}
            {/*<ContactForm title="Write Message" heading="Get In Touch" />*/}
            <Footer3 />
        </>
    );
}

export default Contact;
