import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import brandData from './brandData';

const BrandCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    centerPadding: '0px',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerPadding: '0px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '0px',
        },
      },
    ],
  };

  return (
    <div className="brand-carousel-wrapper">
      <Slider {...settings}>
        {brandData.map((brand) => (
          <div key={brand.id} className="brand-slide d-flex justify-content-center align-items-center">
            <img src={brand.brandLogo} alt="Marcas" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BrandCarousel;
