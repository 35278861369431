import React, {useEffect, useState} from 'react';
import CategoryBtn from '../ProjectFilter/CategoryBtn';
import FilterItem from '../ProjectFilter/FilterItem';
import projectFilterTwoData from './projectFilterTwoData';

function ProjectFilter2() {
    // STATES
    const [category, setCategory] = useState('all');
    const [filteredItem, setFilteredItem] = useState([]);

    useEffect(() => {
        category === 'all'
            ? setFilteredItem(projectFilterTwoData)
            : setFilteredItem(projectFilterTwoData.filter((data) => data.category === category));
    }, [category]);

    return (
        <section className="portfolio-wrapper-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12 text-center">
                        <div className="section-title-2">
                            <span>Trabajos</span>
                            <p>Nuestros</p>
                            <h1>Trabajos</h1>
                        </div>
                    </div>
                </div>

                <div className="row mb-20">
                    <div className="col-12 col-lg-12 text-center">
                        <div className="portfolio-cat-filter">
                            <CategoryBtn
                                className={category === 'all' ? 'btn-active' : null}
                                name="all"
                                label="Mostrar todos"
                                handleSetCategory={setCategory}
                            />
                        </div>
                    </div>
                </div>

                <div className="row grid">
                    {filteredItem.map(
                        (item, length) =>
                            length <= '10' && (
                                <FilterItem
                                    key={item.id}
                                    image={item.image}
                                    name={item.name}
                                    heading={item.heading}
                                    category={item.category}
                                    city={item.city}
                                    url={item.url}
                                />
                            )
                    )}
                </div>
            </div>
        </section>
    );
}

export default ProjectFilter2;
