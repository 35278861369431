import React from 'react';
import {FaFacebookF, FaInstagram, FaTwitter, FaYoutube} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import iconCall from '../../assets/img/icon/call-icon.png';
import iconMap from '../../assets/img/icon/map-icon.png';
import logo from '../../assets/img/personal/logo-eidelca2.png';
import WidExplore from './WidExplore';
import WidNewsletter from './WidNewsletter';
import WidQuestions from './WidQuestions';
import WidServices from './WidServices';

function Footer3() {
    return (
        <footer className="footer-3 footer-wrap">


            <div className="footer-bottom">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-4 col-12 order-2 order-md-1">
                            <div className="copyright-info mt-3 mt-md-0">
                                <p>
                                    &copy; eidelca s.a.s. Todos los derechos reservados. 2024
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 text-center order-1 order-md-2">
                            <div className="footer-logo">
                                <Link className="d-flex align-items-center" to="/">
                                    <img src={logo} alt="eidelca"/>
                                    <div className="justify-content-center">
                                        <h2>eidelca s.a.s</h2>
                                        <h5 className="text-danger">equipos e ingeniería del caribe</h5>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-md-4 col-12 text-md-end order-3 order-md-3">
                            <div className="social_link  mt-3 mt-md-0">
                                <Link to="/home-3">
                                    <FaFacebookF/>
                                </Link>
                                <Link to="/home-3">
                                    <FaTwitter/>
                                </Link>
                                <Link to="/home-3">
                                    <FaInstagram/>
                                </Link>
                                <Link to="/home-3">
                                    <FaYoutube/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer3;
