import React from 'react';
import {AiOutlineMail, AiOutlinePhone} from 'react-icons/ai';
import {
    FaClock,
    FaDribbble,
    FaFacebookF,
    FaLinkedinIn,
    FaMap,
    FaPlus,
    FaTwitter,
    FaYoutube
} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import logo from '../../assets/img/personal/logo-eidelca2.png';
import {WhatsApp} from "@material-ui/icons";

function OffsetMenu({className, handleOffset}) {
    return (
        <div className={className}>
            <span id="offset-menu-close-btn">
                <FaPlus onClick={handleOffset}/>
            </span>
            <div className="offset-menu-wrapper">
                <div className="offset-menu-header">
                    <div className="offset-menu-logo d-flex align-items-center bg-white">
                        <a href="/" className="d-flex align-items-center">
                            <img src={logo} alt="EIDELCA"/>
                            <p className="EID">EIDELCA</p>
                        </a>
                    </div>
                </div>
                <div className="offset-menu-section text-white">
                    <p>
                        Construyendo el futuro y el sueño de nuestros clientes.
                    </p>
                    <Link to="/contact" className="theme-btn mt-30">
                        Consulta
                    </Link>
                </div>
                <div className="offset-menu-section text-white">
                    <h3>Contacto</h3>
                    <ul>
                        <li>
                            <span>
                                <FaMap/>
                            </span>
                            Barrio Bosque - Transversal 44. No. 21a - 44 Local 1.
                        </li>
                        <li>
                            <span>
                                <AiOutlinePhone/>
                            </span>
                            (5) 6566637
                        </li>
                        <li>
                            <span>
                                <AiOutlinePhone/>
                            </span>
                            (+57) 310-713-8609
                        </li>
                        <li>
                            <span>
                                <WhatsApp />
                            </span>
                            (+57) 3502802177
                        </li>
                        <li>
                            <span>
                                <AiOutlineMail/>
                            </span>
                            contacto@eidelca.com
                        </li>
                    </ul>
                </div>
                <div className="offset-menu-footer">
                    <div className="offset-menu-social-icon">
                        <a href="/">
                            <FaFacebookF/>
                        </a>
                        <a href="/">
                            <FaTwitter/>
                        </a>
                        <a href="/">
                            <FaLinkedinIn/>
                        </a>
                        <a href="/">
                            <FaDribbble/>
                        </a>
                        <a href="/">
                            <FaYoutube/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OffsetMenu;
