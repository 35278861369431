import {v4 as uuidv4} from 'uuid';
// Imagenes Cards

//Bdlezo
import img1 from '../../assets/img/personal/bdlezo/img-005.jpg';
import bdlezo_01 from '../../assets/img/personal/bdlezo/bdlez-02.jpg';
import bdlezo_02 from '../../assets/img/personal/bdlezo/bdlezo-01.jpg';
import bdlezo_03 from '../../assets/img/personal/bdlezo/bdlezo-03.jpg';
//Caucasia
import img2 from '../../assets/img/personal/Caucasia/img-006.jpg';
import cau_01 from '../../assets/img/personal/Caucasia/cau-01.jpeg';
import cau_02 from '../../assets/img/personal/Caucasia/cau-02.jpeg';
import cau_03 from '../../assets/img/personal/Caucasia/cau-03.jpeg';

import img3 from '../../assets/img/personal/ensub-001.jpg';
import img4 from '../../assets/img/personal/puerto-boyaca-img.jpeg';
import img5 from '../../assets/img/personal/fiscalia-img.png';
import img6 from '../../assets/img/personal/img-manizalez.jpeg';
import img7 from '../../assets/img/personal/img-mantenimiento.jpg';
import img8 from '../../assets/img/personal/migracion.jpg';
import img9 from '../../assets/img/personal/img-png.jpg';
import img10 from '../../assets/img/personal/img-turbo.jpg';
import img11 from '../../assets/img/personal/img-uspec.jpg';
// Imagenes Proyectos
import img_p1 from '../../assets/img/personal/TRABAJADORES.jpg';

const projectFilterTwoData = [
    {
        id: 'ED001',
        image: img1,
        img_p1: bdlezo_01,
        img_p2: bdlezo_02,
        img_p3: bdlezo_03,
        name: 'Construcción',
        price: '6.400.000.000',
        heading: 'Hospital de Blaz de Lezo',
        category: 'cartagena',
        city: 'Cartagena',
        client: 'ESE',
        desc: 'Mantenimiento preventivo y correctivo y/o mejoras locativas de las instalaciones de la e.S.E hospital local ' +
            'cartagena de indias a precios unitarios fijos sin fórmula de reajuste, con ejecución de tracto sucesivo',
        url: '/detalles/ED001',
    },

    {
        id: 'ED002',
        image: img2,
        img_p1: cau_01,
        img_p2: cau_02,
        img_p3: cau_03,
        name: 'Obra',
        price: '1.799.407.771',
        heading: 'Nombre del proyecto',
        category: 'antioquia',
        city: 'Antioquia',
        client: 'Institución educativa normal superior del bajo cauca',
        desc: 'construcción de restaurante escolar en la institución educativa normal superior del bajo cauca, municipio de caucasia, antioquia.',
        value: '722821519',
        url: '/detalles/ED002',
    },
    {
        id: 'ED003',
        image: img3,
        img_p1: img4,
        img_p2: img5,
        img_p3: img6,
        name: 'Obra',
        price: '1.799.407.771',
        heading: 'Nombre del proyecto',
        category: 'antioquia',
        city: 'Antioquia',
        client: 'Institución educativa normal superior del bajo cauca',
        desc: 'construcción de restaurante escolar en la institución educativa normal superior del bajo cauca, municipio de caucasia, antioquia.',
        value: '722821519',
        url: '/detalles/ED003',
    },

];

export default projectFilterTwoData;
