import React from 'react';
import Cta from '../components/Cta';
import Header1 from '../components/Header1';
import Hero1 from '../components/Hero1';
import Services1 from '../components/Services1';
import Footer3 from "../components/Footer3";
import Porfolio1 from "../components/Portfolio1";
import BrandCarousel from "../components/BrandCarousel";

function Home1() {
    return (
        <>
            <Header1/>
            <Hero1/>
            <Services1/>
            <Porfolio1/>
            {/*<Cta />*/}
            <div className="col-12 col-lg-12 text-center">
                <div className="section-title-2">
                    <h1>Nuestros Clientes</h1>
                </div>
            </div>
            <BrandCarousel/>
            <Cta/>
            <Footer3/>
        </>
    );
}

export default Home1;
