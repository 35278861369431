import React from 'react';
import galleryImg1 from '../../assets/img/personal/NosotrosSmall.png';
import galleryImg2 from '../../assets/img/personal/TRABAJADORES.jpg';
import galleryImg3 from '../../assets/img/personal/cau-img-003.jpeg';
import {useParams} from "react-router-dom/cjs/react-router-dom";
import projectFilterTwoData from "../ProjectFilter2/projectFilterTwoData";
import Gallery from "../Gallery";

function PortfolioDetails() {
    let {id} = useParams();
    console.log(id)
    const project = projectFilterTwoData.find(p => p.id === id);
    if (!project) {
        return <div>Proyecto no encontrado</div>;
    }

    return (
        <section className="project-details-wrapper section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div
                            className="project-thumb bg-cover"
                            style={{
                                backgroundImage: `url(${project.image})`,
                            }}
                        />
                        <div className="project-meta-data">
                            <div className="project-info">
                                <span>Tipo</span>
                                <h3>{project.name}</h3>
                            </div>
                            <div className="project-info">
                                <span>Nombre</span>
                                <h3>{project.heading}</h3>
                            </div>
                            <div className="project-info">
                                <span>Ciudad</span>
                                <h3>{project.city}</h3>
                            </div>
                            <div className="project-info">
                                <span>Valor</span>
                                <h3>{project.price}</h3>
                            </div>
                        </div>
                        <div className="project-details-content row mt-50">
                            <p className="col-lg-12 col-sm-12">
                                {project.desc}
                            </p>
                            <br/>
                            <div className="row">
                                <div className="col-12 col-lg-12 text-center">
                                    <div className="section-title-2">
                                        <h1>Imágenes</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <Gallery images={[project.img_p1, project.img_p2, project.img_p3]}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PortfolioDetails;
