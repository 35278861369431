import React from 'react';
import bannerBg from '../assets/img/personal/Nosotros.png';
import bannerBgSm from '../assets/img/personal/NosotrosSmall.png';
import AboutFeatured from '../components/AboutFeatured';
import Approch from '../components/Approch';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';
import Footer3 from "../components/Footer3";

function About() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBgDesktop={bannerBg} bannerBgMobile={bannerBgSm} currentPage="Nosotros" heading="Nosotros" />
            <AboutFeatured />
            <Approch />
            <Footer3 />
        </>
    );
}

export default About;
