import React, {useState} from 'react';
import {FaClipboardCheck, FaHammer, FaHardHat, FaRoad} from 'react-icons/fa';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import aboutImg from '../../assets/img/personal/img-003.jpg';
import btnImg from '../../assets/img/personal/img-004.jpg';
import IconBox from './IconBox';

function AboutFeatured() {
    const [isOpen, setOpen] = useState(false);
    return (
        <>
            <ModalVideo
                channel="youtube"
                autoplay
                isOpen={isOpen}
                videoId="EZ9DrY43wtw"
                onClose={() => setOpen(false)}
            />
            <section className="about-featured-wrapper section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6 col-lg-5 col-12">
                            <div
                                className="about-promo bg-cover"
                                style={{backgroundImage: `url(${aboutImg})`}}
                            >
                                <div
                                    className="skill-popup-video d-flex justify-content-center align-items-center bg-cover"
                                    style={{backgroundImage: `url(${btnImg})`}}
                                >
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
                            <div className="block-contents ml-lg-5">
                                <h1>eidelca s.a.s</h1>
                                <h4>
                                    Equipos e ingeniería del caribe.
                                </h4>
                            </div>

                            <div className="icon-boxs ml-lg-5">
                                <IconBox
                                    icon={<FaHardHat className="icon-color"/>}
                                    heading="Constructores"
                                    text="Especialistas en la creación de infraestructuras robustas y diseño
                                    arquitectónico moderno, comprometidos con la calidad y la innovación en cada obra."
                                />
                                <IconBox
                                    icon={<FaClipboardCheck className="icon-color"/>}
                                    heading="Consultores"
                                    text="Asesoría experta en ingeniería y gestión ambiental, ofreciendo soluciones
                                    estratégicas y optimización de proyectos a medida."
                                />
                                <IconBox
                                    icon={<FaHammer className="icon-color"/>}
                                    heading="Proveedores"
                                    text="Provisión de materiales de alta calidad y equipos de vanguardia para la
                                    industria de la construcción, con logística eficiente y soporte técnico."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutFeatured;
