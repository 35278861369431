import { FaCity, FaDraftingCompass, FaHardHat, FaRegBuilding, FaTruckMoving } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import thumb1 from '../../assets/img/personal/img-004.jpg';
import thumb2 from '../../assets/img/personal/alquiler.jpeg';
import thumb3 from '../../assets/img/personal/personal-img-001v2.jpg';

const servicesTwoData = [
    {
        id: uuidv4(),
        thumbnail: thumb1,
        icon: <FaDraftingCompass className="text-white" />,
        heading: 'Construcción',
        text: 'Nos encargamos de construir tu proyecto.',
        // url: '/services-details',
    },

    {
        id: uuidv4(),
        thumbnail: thumb2,
        icon: <FaRegBuilding className="text-white"/>,
        heading: 'Alquiler',
        text: 'Facilitamos las herramientas y suministros para tu proyecto',
        // url: '/services-details11',
    },

    {
        id: uuidv4(),
        thumbnail: thumb3,
        icon: <FaTruckMoving className="text-white"/>,
        heading: 'Consultoría',
        text: 'Seremos tu guia en el proceso, asesorando sobre tus necesidades',
        // url: '/services-details22',
    },
];

export default servicesTwoData;
