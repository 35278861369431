import React from 'react';

function Map() {

    return (
        <div id="map">
            <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3924.2479081795527!2d-75.52278522496336!3d10.401874489725076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDI0JzA2LjgiTiA3NcKwMzEnMTIuOCJX!5e0!3m2!1ses-419!2sco!4v1706045361083!5m2!1ses-419!2sco"
                frameBorder="0"
                style={{
                    border: '0',
                    width: '100%',
                }}
                allowFullScreen=""
                aria-hidden="false"
            />
        </div>
    );
}

export default Map;
