import React from 'react';
import {Link} from 'react-router-dom';
import {FaCheck, MdLocationOn} from "react-icons/all";

function FilterItem({image, name, heading, category, city, value, url}) {
    return (
        <div className="col-xl-3 col-md-6 grid-item bedroom card-custom mt-3">
            <Link to={url}>
                <div className="single-cause-item">
                    <div className="cause-bg bg-cover" style={{backgroundImage: `url(${image})`}}>
                        <div className="icon">
                            <FaCheck/>
                        </div>
                    </div>
                    <div className="cause-content">
                        <div className="cause-meta d-flex">
                            <div className="author mr-15">{name}</div>
                            |
                        </div>
                        <h4>
                            <div>{heading}</div>
                        </h4>
                        <div>
                            <h5 className="text-white mt-4 text-end"><MdLocationOn color="white"/>{city}</h5>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default FilterItem;
