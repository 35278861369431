import React from 'react';
import bannerBg from '../assets/img/page-banner.jpg';
import Header1 from '../components/Header1';
import PageBanner from '../components/PageBanner';

function Team() {
    return (
        <>
            <Header1 />
            <PageBanner bannerBg={bannerBg} heading="Our Expert Team" currentPage="Team" />
        </>
    );
}

export default Team;
