import { v4 as uuidv4 } from 'uuid';
import logo1 from '../../assets/img/personal/brands/nobg/Esse.png';
import logo2 from '../../assets/img/personal/brands/nobg/army.png';
import logo3 from '../../assets/img/personal/brands/nobg/fiscalia.png';
import logo4 from '../../assets/img/personal/brands/nobg/sena.png';
import logo5 from '../../assets/img/personal/brands/nobg/Logo_rama_judicial_colombia.png';
import logo6 from '../../assets/img/personal/brands/nobg/uspecnb.png';
import logo7 from '../../assets/img/personal/brands/nobg/log_fuerza_aeroespacialf.png';
import logo8 from '../../assets/img/personal/brands/nobg/def_pueblo.png';
import logo9 from '../../assets/img/personal/brands/nobg/procuraduria.png';
import logo10 from '../../assets/img/personal/brands/nobg/policia.png';
import logo11 from '../../assets/img/personal/brands/nobg/servi.png';
import logo12 from '../../assets/img/personal/brands/nobg/logo-inpec.png';

const brandData = [
    {
        id: uuidv4(),
        brandLogo: logo1,
    },

    {
        id: uuidv4(),
        brandLogo: logo2,
    },

    {
        id: uuidv4(),
        brandLogo: logo3,
    },

    {
        id: uuidv4(),
        brandLogo: logo4,
    },

    {
        id: uuidv4(),
        brandLogo: logo5,
    },

    {
        id: uuidv4(),
        brandLogo: logo6,
    },

    {
        id: uuidv4(),
        brandLogo: logo7,
    },

    {
        id: uuidv4(),
        brandLogo: logo8,
    },

    {
        id: uuidv4(),
        brandLogo: logo9,
    },

    {
        id: uuidv4(),
        brandLogo: logo10,
    },

    {
        id: uuidv4(),
        brandLogo: logo11,
    },

    {
        id: uuidv4(),
        brandLogo: logo12,
    },
];

export default brandData;
