import React from 'react';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';

const menuData = [
    {
        title: 'Inicio',
        path: '/',
    },

    {
        title: 'Nosotros',
        path: '/about',
    },

    {
        title: 'Servicios',
        path: '/services',
    },

    {
        title: 'Contacto',
        path: '/contact',
    },
];

export default menuData;
