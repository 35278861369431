import React, {useEffect, useState} from 'react';

function PageBanner({bannerBgDesktop, bannerBgMobile, currentPage, heading}) {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [bannerBg, setBannerBg] = useState(bannerBgDesktop);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        // Establece la imagen de fondo según el tamaño de la ventana
        setBannerBg(windowWidth >= 768 ? bannerBgDesktop : bannerBgMobile);

        // Limpieza del event listener
        return () => window.removeEventListener('resize', handleWindowResize);
    }, [windowWidth, bannerBgDesktop, bannerBgMobile]);
    return (
        <section
            className="page-banner-wrap bg-cover"
            style={{backgroundImage: `url(${bannerBg})`}}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="breadcrumb-wrap">
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <a href="index.html">Inicio</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {currentPage}
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div className="page-heading text-white">
                            <h1>{heading}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PageBanner;
