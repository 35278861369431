import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import Logo from '../../assets/img/personal/logo-eidelca.png';
import MobileMenu from '../MobileMenu';
import OffsetMenu from '../OffsetMenu';
import MainMenu from './MainMenu';
import Topbar from './Topbar';
import {FiMail, FiPhone} from "react-icons/all";
import {WhatsApp} from "@material-ui/icons";

function Header1() {
    const [offset, setOffset] = useState(false);
    const handleOffset = () => {
        setOffset(!offset);
    };
    return (
        <>
            <OffsetMenu
                handleOffset={handleOffset}
                className={offset ? 'offset-menu show' : 'offset-menu'}
            />
            <header className="header-wrap header-1">
                <Topbar/>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-8 col-lg-4">
                            <div className="logo">
                                <Link to="/">
                                    <div className="d-flex align-items-center">
                                        <img src={Logo} alt="logo"/>
                                        <div className="justify-content-center">
                                            <h2>eidelca s.a.s</h2>
                                            <h5 className="text-danger">equipos e ingeniería del caribe</h5>
                                        </div>

                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-4 d-block d-lg-none">
                            <div className="mobile-nav-wrap">
                                <MobileMenu/>
                            </div>
                        </div>
                        <div className="col-12 d-none d-md-block col-lg-8">
                            <div className="header-contact-info text-lg-right">
                                <div className="single-element">
                                    <a href="/"><WhatsApp/>(+57) 3502802177</a>
                                    <span>Vía WhatsApp</span>
                                </div>
                                <div className="single-element">
                                    <a href="/"><FiMail/> contacto@eidelca.com</a>
                                    <span>Escríbenos</span>
                                </div>
                                <div className="header-btn d-inline zoom-hover-effect">
                                    <Link to="/services" className="theme-btn">
                                        Más información
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu-wrapper d-none d-lg-block">
                    <div className="container">
                        <MainMenu hanldeOffset={handleOffset}/>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header1;
