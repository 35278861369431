import {FaDraftingCompass, FaHardHat, FaPencilRuler} from 'react-icons/fa';
import {v4 as uuidv4} from 'uuid';
import thumb1 from '../../assets/img/personal/cau-img-001.jpeg';
import thumb2 from '../../assets/img/personal/cau-img-003.jpeg';
import thumb3 from '../../assets/img/personal/vision.jpeg';

const approchData = [
    {
        id: uuidv4(),
        thumb: thumb1,
        icon: <FaDraftingCompass className="text-white"/>,
        heading: 'Nuestra Visión',
        text: 'En el año 2020 EIDELCA S.A.S. será una de las empresas lideres en la consultoría y\n' +
            'construcción en la costa atlántica a través de un proceso permanente de capacitación de\n' +
            'los socios y personal que hace parte de ella, obteniendo solidez mediante la implementación\n' +
            'de una planeación estratégica que permita llevar a cabo un proceso de retroalimentación\n' +
            'con el fin de obtener altos niveles de eficiencia, eficacia y rentabilidad, logrando con esto\n' +
            'alcanzar máxima confianza en nuestros proveedores y clientes tanto locales como\n' +
            'nacionales. ',
    },

    {
        id: uuidv4(),
        thumb: thumb2,
        icon: <FaPencilRuler className="text-white"/>,
        heading: 'Nuestra Misión',
        text: 'Somos una empresa que contribuye al desarrollo del nivel de vida de la comunidad,\n' +
            'mejorando el entorno mediante la consultoría y construcción de obras civiles, sanitarias,\n' +
            'ambientales y afines.\n' +
            'La estructura, organización y funcionamiento administrativo pertenece una empresa ágil y\n' +
            'eficiente con un alto nivel de tecnología y productividad.\n' +
            'Contamos con una base de tecnología que responde a las exigencias del desarrollo\n' +
            'contemporáneo y con un recurso humano calificado, con sentido de pertenencia e\n' +
            'identificado con los planes y políticas de la organización. ',
    },

    {
        id: uuidv4(),
        thumb: thumb3,
        icon: <FaHardHat className="text-white"/>,
        heading: 'Nuestros Valores',
        text: 'EIDELCA S.A.S, se identifica por el trabajo en equipo, cumplimiento, responsabilidad y calidad en ' +
            'la prestación de sus servicios de ingeniería de consultoría y construcción.',
    },
];

export default approchData;
